// fluidly resize type
// based on example here https://css-tricks.com/snippets/css/fluid-typography/
@mixin fluid-type($font-min, $font-max, $screen-min, $screen-max) {
  font-size: #{$font-min}px;

  @media only screen and (min-width: #{$screen-min}px) {
    font-size: calc(
      #{$font-min}px + #{($font-max - $font-min)} * (100vw - #{$screen-min}px) / (#{$screen-max} - #{$screen-min})
    );
  }

  @media only screen and (min-width: #{$screen-max}px) {
    font-size: #{$font-max}px;
  }
}
